
<script>

    export let d;
    export let size;


    import ItemPriceCatcher from '$lib/components/Cards/ItemPriceCatcher.svelte';

    import {
        shortenWord
    } from "$lib/components/Utilities.svelte"

    import * as Card from "$lib/components/ui/card"



</script>



{#if d}

    <Card.Content class="m-0 p-0">

        <div
            class="z-40"
            style="
                margin-top:-12px;
                margin-bottom:-20px;
            ">
            <ItemPriceCatcher
                size={ size=='sm'?100:150 }
                item_code={d.lookup_item?.item_code}
                item={d.lookup_item?.item}
            />
        </div>


        <div class="pt-4 px-1 text-xs min-h-[3rem] text-center m-0 group-hover:text-[white]">
            {@html shortenWord(d.lookup_item?.item)}
        </div>


    </Card.Content>
{/if}
